import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css"
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

const Authentication = () => {
  const [returnAuthenticationModalShow, setReturnAuthenticationModalShow] = React.useState(false);
  const [input_username, setInputUsername] = useState("");
  const [input_password, setInputPassword] = useState("");

  const theme = createTheme({
    palette: {
      grey: {
        main: '#607d8b',
        light: '#78909c',
        dark: '#78909c',
        contrastText: '#ffffff',
      },
    },
  });

  const AuthenticationHandle = () => {
    fetch(process.env.REACT_APP_API_BASE_URL + "authentication", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: input_username,
        password: input_password
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "200") {
        localStorage.setItem("token", data.token);
        window.location.href = "./Statistics"
      } else {
        setReturnAuthenticationModalShow(true)
      }
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  }

  function AuthenticationReturnModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <div className="mt-2 display-6">Authentication Failed</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  
  return <>
    <AuthenticationReturnModal
      show={returnAuthenticationModalShow}
      onHide={() => setReturnAuthenticationModalShow(false)}
    />

    <div className="row m-0">
      <div className="col-0 col-lg-3 p-3"></div>
      <div className="col-12 col-lg-6 p-3">
        <div className="authentication-frame card-xl p-4">
          <div className="row">
            <div className="col-0 col-lg-2">
            </div>
            <div className="col-12 col-lg-8 my-5">
              <img alt="VOXA Logo" className="authentication-logo d-block m-auto" src="/images/fulllogo.png" />
              <TextField label="Username" value={input_username} onChange={(e) => { setInputUsername(e.target.value) }} className="form-control mt-5" required={true} />
              <TextField label="Password" type="password" value={input_password} onChange={(e) => { setInputPassword(e.target.value) }} className="form-control mt-4" required={true} />
              <Button onClick={() => { AuthenticationHandle(); }} className="w-100 mt-4 mb-2" variant="contained" startIcon={<LoginIcon />}>Login</Button>
              <div className="alert alert-danger mt-4" role="alert">
                Use of this system is restricted to individuals and activities authorized by the Management of the VOXA Group. Unauthorized use may result in appropriate disciplinary action and/or legal prosecution.
              </div>
            </div>
            <div className="col-0 col-lg-2">
            </div>
          </div>
        </div>
      </div>
      <div className="col-0 col-lg-3 p-3"></div>
    </div >
  </>;
};

export default Authentication;