import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import AnimatedNumber from "animated-number-react";

function getValue(value) {
  if (value === "" || value === undefined)
    return "Others"
  else
    return value
}

const Statistics = () => {
  const [activecard, setActiveCard] = useState(0);
  const [inactivecard, setInActiveCard] = useState(0);
  const [activebusiness, setActiveBusiness] = useState(0);
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [platform, setPlatform] = useState("");
  const [frequencybusiness, setFrequencyBusiness] = useState("");
  const [frequencyuser, setFrequencyUser] = useState("");
  const [userchart, setUserChart] = useState({});
  const [countrychart, setCountryChart] = useState({});
  const [citychart, setCityChart] = useState({});
  const [platformchart, setPlatformChart] = useState({});
  const [frequencybusinesschart, setFrequencyBusinessChart] = useState({});
  const [frequencyuserchart, setFrequencyUserChart] = useState({});

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "statistics/getInfo", {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      let mobileallcount = 0
      let computerallcount = 0

      let chart_color = ["#5470c6", "#91cc75", "#fac858", "#ee6666"]
      let userchart_x = []
      let userchart_y = []
      let frequencybusiness_x = []
      let frequencybusiness_y = []
      let frequencyuser_x = []
      let frequencyuser_y = []
      let countrychart_data = []
      let citychart_data = []
      let platformchart_data = []

      // Data Handle
      data.result1.forEach((element, index) => {
        userchart_x.push(element.name)
        userchart_y.push({
          value: element.count,
          itemStyle: { color: chart_color[index] }
        })
      });

      data.result2.forEach((element, index) => {
        countrychart_data.push({ value: element.count, name: element.geo_country })
      });

      data.result3.forEach((element, index) => {
        citychart_data.push({ value: element.count, name: element.geo_city })
      });

      data.result4.forEach((element, index) => {
        mobileallcount += parseInt(element.mobile_count)
        computerallcount += parseInt(element.computer_count)
        platformchart_data.push({
          name: element.useragent_name,
          type: 'bar',
          stack: 'one',
          data: [element.mobile_count, element.computer_count]
        });
      });

      data.result5.forEach((element, index) => {
        frequencybusiness_x.push(getValue(element.name))
        frequencybusiness_y.push({
          value: element.count,
          itemStyle: { color: chart_color[index] }
        })
      });

      data.result6.forEach((element, index) => {
        frequencyuser_x.push(getValue(element.name))
        frequencyuser_y.push({
          value: element.count,
          itemStyle: { color: chart_color[index] }
        })
      });

      // Chart
      const city_chart = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: true,
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: citychart_data
          }
        ]
      };

      const platform_chart = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          data: ['Mobile', 'Computer'],
        },
        yAxis: {},
        series: platformchart_data
      };

      const user_chart = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: userchart_x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: userchart_y,
            type: 'bar'
          }
        ]
      };

      const country_chart = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: true,
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: countrychart_data
          }
        ]
      };

      const frequencybusiness_chart = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: frequencybusiness_x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: frequencybusiness_y,
            type: 'bar'
          }
        ]
      };

      const frequencyuser_chart = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: frequencyuser_x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: frequencyuser_y,
            type: 'bar'
          }
        ]
      };

      setActiveCard(data.count.user_count);
      setInActiveCard(0);
      setActiveBusiness(data.count.business_count);
      setCompany(getValue(data.result1[0].name))
      setCountry(getValue(data.result2[0].geo_country))
      setCity(getValue(data.result3[0].city))
      computerallcount > mobileallcount ? setPlatform("Computer") : setPlatform("Mobile")
      setFrequencyBusiness(getValue(data.result5[0].name))
      setFrequencyUser(getValue(data.result6[0].name))

      setUserChart(user_chart)
      setCountryChart(country_chart)
      setCityChart(city_chart)
      setPlatformChart(platform_chart)
      setFrequencyBusinessChart(frequencybusiness_chart)
      setFrequencyUserChart(frequencyuser_chart)

    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  }, []);

  return <>
    <div className="row m-0">
      <div className="col-12 col-lg-6 col-xl-4 p-0">
        <div className="d-flex flex-column p-3 h-100">
          <div className="card-main-md p-4 mb-4">
            <h1 className="fw-bold">
              <AnimatedNumber
                value={activecard}
                formatValue={value => `${Number(value).toFixed(0)}`}
              />
            </h1>
            <div className="py-1 fw-bold">Active Users</div>
            <div className="mt-auto">
              <h5 className="fw-bold">
                <AnimatedNumber
                  value={inactivecard}
                  formatValue={value => `${Number(value).toFixed(0)}`}
                />
              </h5>
              <div className="py-1 fw-bold">InActive Card</div>
            </div>
          </div>
          <div className="card-md p-4 mt-auto">
            <h1 className="fw-bold">
              <AnimatedNumber
                value={activebusiness}
                formatValue={value => `${Number(value).toFixed(0)}`}
              />
            </h1>
            <div className="py-1 fw-bold text-black-50">Business Company</div>
            <div className="mt-auto">
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-8 p-3">
        <div className="card-lg p-4">
          <h1 className="fw-bold">{company}</h1>
          <div className="py-1 fw-bold text-black-50">Top Five Business</div>
          <div className="mt-auto">
            <ReactECharts option={userchart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-4 p-3">
        <div className="card-xl p-4">
          <h1 className="fw-bold">{country}</h1>
          <div className="py-1 fw-bold text-black-50">Top Five Country</div>
          <div className="mt-auto">
            <ReactECharts option={countrychart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-4 p-3">
        <div className="card-xl p-4">
          <h1 className="fw-bold">{city}</h1>
          <div className="py-1 fw-bold text-black-50">Top Five City</div>
          <div className="mt-auto">
            <ReactECharts option={citychart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-4 p-3">
        <div className="card-xl p-4">
          <h1 className="fw-bold">{platform}</h1>
          <div className="py-1 fw-bold text-black-50">Top Five Platform</div>
          <div className="mt-auto">
            <ReactECharts option={platformchart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 p-3">
        <div className="card-xl p-4">
          <h1 className="fw-bold">{frequencybusiness}</h1>
          <div className="py-1 fw-bold text-black-50">Total Frequency Group by Business Company</div>
          <div className="mt-auto">
            <ReactECharts option={frequencybusinesschart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 p-3">
        <div className="card-xl p-4">
          <h1 className="fw-bold">{frequencyuser}</h1>
          <div className="py-1 fw-bold text-black-50">Top Five Frequency Group By User</div>
          <div className="mt-auto">
            <ReactECharts option={frequencyuserchart} style={{ height: 400 }} />
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default Statistics;