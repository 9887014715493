import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import CountryCode from "../pages/components/CountryCode.jsx";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Resizer from "react-image-file-resizer";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProfileTemplate1 from "./template/Profile.jsx";
import Modal from 'react-bootstrap/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Email from '@mui/icons-material/Email';
import LocationOn from '@mui/icons-material/LocationOn';
import LocalPhone from '@mui/icons-material/LocalPhone';
import DeleteIcon from '@mui/icons-material/Delete';

const Account = () => {
  let { shortid } = useParams()
  const [value, setValue] = React.useState('1');
  const [businessdata, setBusinessData] = useState([]);
  const [countrycode] = useState(CountryCode());

  const [returnUpdateModalShow, setReturnUpdateModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [returnDeleteModalShow, setReturnDeleteModalShow] = React.useState(false);

  const [input_shortid, setInputShortID] = useState("");
  const [input_id, setInputID] = useState("");
  const [input_businessid, setInputBusinessID] = useState("");
  const [input_profile_image, setInputProfileImage] = useState(process.env.REACT_APP_NO_PROFILE_IMAGE_URL);
  const [new_profile_image, setNewProfileImage] = useState(null);
  const [input_company_image, setInputCompanyImage] = useState(process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL);

  const [input_firstname, setInputFirstName] = useState("");
  const [input_lastname, setInputLastName] = useState("");
  const [input_suffix, setInputSuffix] = useState("");
  const [input_title, setInputTitle] = useState("");
  const [input_department, setInputDepartment] = useState("");

  const [input_address, setInputAddress] = useState([]);
  const [input_email, setInputEmail] = useState([]);
  const [input_phonenum, setInputPhonenum] = useState([]);

  const [input_urlname_linkedin, setInputUrlnameLinkedin] = useState("");
  const [input_urllink_linkedin, setInputUrllinkLinkedin] = useState("");
  const [input_urlname_company, setInputurlnameCompany] = useState("");
  const [input_urllink_company, setInputUrllinkCompany] = useState("");
  const [input_urlname_facebook, setInputUrlnameFacebook] = useState("");
  const [input_urllink_facebook, setInputUrllinkFacebook] = useState("");
  const [input_urlname_twitter, setInputUrlnameTwitter] = useState("");
  const [input_urllink_twitter, setInputUrllinkTwitter] = useState("");
  const [input_urlname_custom, setInputUrlnameCustom] = useState("");
  const [input_urllink_custom, setInputUrllinkCustom] = useState("");
  const [input_urlname_personal, setInputUrlnamePersonal] = useState("");
  const [input_urllink_personal, setInputUrllinkPersonal] = useState("");
  const [input_company, setInputCompany] = useState("");
  const [input_headline, setInputHeadline] = useState("");
  const [input_notes, setInputNotes] = useState("");
  const [input_field, setInputField] = useState([]);

  const theme = createTheme({
    palette: {
      grey: {
        main: '#607d8b',
        light: '#78909c',
        dark: '#78909c',
        contrastText: '#ffffff',
      },
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleProfileImageUpload = (event) => {
    const elem = document.getElementById("account-upload-profileimage");
    elem.click()
  }

  const handleProfileImageChange = (event) => {
    setNewProfileImage(event.target.files[0]);
    setInputProfileImage(URL.createObjectURL(event.target.files[0]));
  }

  const handleSelectBusinessIDChange = (event) => {
    setInputBusinessID(event.target.value);

    businessdata.forEach((element, index) => {
      if (element.businessid === event.target.value)
        setInputCompanyImage(element.company_image)
    });
  };

  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, 500, 500,
      "PNG", 100, 0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const updateUser = async () => {
    let profile_image = input_profile_image;

    if (new_profile_image != null) {
      profile_image = await resizeFile(new_profile_image)
    }

    var json = {
      "shortid": input_shortid,
      "businessid": input_businessid,
      "profile_image": profile_image,
      "firstname": input_firstname,
      "lastname": input_lastname,
      "suffix": input_suffix,
      "title": input_title,
      "department": input_department,
      "urlname_linkedin": input_urlname_linkedin,
      "urllink_linkedin": input_urllink_linkedin,
      "urlname_company": input_urlname_company,
      "urllink_company": input_urllink_company,
      "urlname_facebook": input_urlname_facebook,
      "urllink_facebook": input_urllink_facebook,
      "urlname_twitter": input_urlname_twitter,
      "urllink_twitter": input_urllink_twitter,
      "urlname_custom": input_urlname_custom,
      "urllink_custom": input_urllink_custom,
      "urlname_personal": input_urlname_personal,
      "urllink_personal": input_urllink_personal,
      "company": input_company,
      "headline": input_headline,
      "notes": input_notes,
      "field": JSON.stringify(input_field)
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "business/updateUser", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      setReturnUpdateModalShow(true)
    }).catch((err) => {
        window.location.href = "./Authentication"
    });
  }

  const deleteUser = () => {
    var json = {
      "shortid": input_shortid
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "business/deleteUser", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      setDeleteModalShow(false)
      setReturnDeleteModalShow(true)
    }).catch((err) => {
        window.location.href = "./Authentication"
    });
  };

  const addPhonenumLabel = () => {
    setInputField([...input_field, { item: "phone", type: "work", value: "", countrycode: "+(852)" }]);
  };

  const addEmailLabel = () => {
    setInputField([...input_field, { item: "email", type: "work", value: "" }]);
  };

  const addAddressLabel = () => {
    setInputField([...input_field, { item: "address", type: "work", value: "" }]);
  };

  const deleteFieldLabel = (index) => {
    const array = [...input_field];
    array.splice(index, 1);
    setInputField(array);
  };

  const handleFieldCountryCodeChange = (event, index) => {
    let { value } = event.target;
    let onChangeValue = [...input_field];
    onChangeValue[index]["countrycode"] = value;
    setInputField(onChangeValue);
  };

  const handleFieldChange = (event, index) => {
    let { value } = event.target;
    let onChangeValue = [...input_field];
    onChangeValue[index]["value"] = value;
    setInputField(onChangeValue);
  };

  const handleFieldTypeChange = (event, index) => {
    let { value } = event.target;
    let onChangeValue = [...input_field];
    onChangeValue[index]["type"] = value;
    setInputField(onChangeValue);
  };

  useEffect(() => {
    let emaillist = []
    let phonelist = []
    let addresslist = []
    input_field.forEach((element, index) => {
      if (element.item === "email")
        emaillist.push({ 'type': element.type, 'value': element.value })
      else if (element.item === "phone")
        phonelist.push({ 'type': element.type, 'value': element.countrycode + " " + element.value })
      else if (element.item === "address")
        addresslist.push({ 'type': element.type, 'value': element.value })
    });
    setInputEmail(emaillist);
    setInputPhonenum(phonelist);
    setInputAddress(addresslist);
  }, [input_field]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "settings/getBusiness", {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }
      
      let business_data = []
      data.result.forEach((element, index) => {
        business_data.push({ 'businessid': element.businessid, 'name': element.name, 'company_image': element.company_image })
      });

      setBusinessData(business_data)
    }).catch((err) => {
        window.location.href = "./Authentication"
    });

    fetch(process.env.REACT_APP_API_BASE_URL + "business/info/" + shortid, {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }
      
      if (data.result1.length > 0) {
        setInputShortID(data.result1[0].shortid)
        setInputID(data.result1[0].id)
        setInputBusinessID(data.result1[0].businessid)
        setInputProfileImage(data.result1[0].profile_image || "");
        setInputCompanyImage(data.result1[0].company_image || "");
        setInputFirstName(data.result1[0].firstname || "");
        setInputLastName(data.result1[0].lastname || "");
        setInputSuffix(data.result1[0].suffix || "");
        setInputTitle(data.result1[0].title || "");;
        setInputDepartment(data.result1[0].department || "");
        setInputUrlnameLinkedin(data.result1[0].urlname_linkedin || "");
        setInputUrllinkLinkedin(data.result1[0].urllink_linkedin || "");
        setInputurlnameCompany(data.result1[0].urlname_company || "");
        setInputUrllinkCompany(data.result1[0].urllink_company || "");
        setInputUrlnameFacebook(data.result1[0].urlname_facebook || "");
        setInputUrllinkFacebook(data.result1[0].urllink_facebook || "");
        setInputUrlnameTwitter(data.result1[0].urlname_twitter || "");
        setInputUrllinkTwitter(data.result1[0].urllink_twitter || "");
        setInputUrlnameCustom(data.result1[0].urlname_custom || "");
        setInputUrllinkCustom(data.result1[0].urllink_custom || "");
        setInputUrlnamePersonal(data.result1[0].urlname_personal || "");
        setInputUrllinkPersonal(data.result1[0].urllink_personal || "");
        setInputCompany(data.result1[0].company || "");
        setInputHeadline(data.result1[0].headline || "");
        setInputNotes(data.result1[0].notes || "");

        let fieldlist = [];
        data.result2.forEach((element, index) => {
          if (element.item === "phone") {
            let field_countrycode = ""
            let field_num = ""
            if (element.value.split(" ").length > 1) {
              field_countrycode = element.value.split(" ")[0]
              field_num = element.value.replace(field_countrycode, "")
            } else {
              field_countrycode = "+(852)";
              field_num = element.value;
            }

            fieldlist.push({ item: element.item, 'type': element.type, 'value': field_num, 'countrycode': field_countrycode })
          } else {
            fieldlist.push({ item: element.item, 'type': element.type, 'value': element.value })
          }
        });
        setInputField(fieldlist);
      } else {
        window.location.href = '../management'
      }

    }).catch((err) => {
        window.location.href = "./Authentication"
    });
  }, [shortid]);

  function UpdateReturnModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record updated</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={() => window.location.reload()} className="px-5 mx-3" variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DeleteModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <div className="mt-2 display-6">Are you sure?</div>
            <div className="mt-4 text-secondary"> Do you really want to delete these records? This process cannot be undone</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Cancel</Button>
            </ThemeProvider>
            <Button onClick={() => deleteUser()} className="px-5 mx-3" variant="contained" color="error">Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DeleteReturnModal(props) {
    return (
      <Modal
        {...props}
        onHide={() => window.location.href = '../management'}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record removed</div>
            <div className="mt-4 text-secondary">The system will redirect to Account page</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={() => window.location.href = '../management'} variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return <>
    <UpdateReturnModal
      show={returnUpdateModalShow}
      onHide={() => setReturnUpdateModalShow(false)}
    />

    <DeleteModal
      show={deleteModalShow}
      onHide={() => setDeleteModalShow(false)}
    />

    <DeleteReturnModal
      show={returnDeleteModalShow}
      onHide={() => setReturnDeleteModalShow(false)}
    />
    <div className="row m-0">
      <div className="col-12 col-lg-6 p-3">
        <ProfileTemplate1
          input_profile_image={input_profile_image}
          input_company_image={input_company_image}
          input_firstname={input_firstname}
          input_lastname={input_lastname}
          input_suffix={input_suffix}
          input_title={input_title}
          input_department={input_department}
          input_urlname_linkedin={input_urlname_linkedin}
          input_urllink_linkedin={input_urllink_linkedin}
          input_urlname_company={input_urlname_company}
          input_urllink_company={input_urllink_company}
          input_urlname_facebook={input_urlname_facebook}
          input_urllink_facebook={input_urllink_facebook}
          input_urlname_twitter={input_urlname_twitter}
          input_urllink_twitter={input_urllink_twitter}
          input_urlname_custom={input_urlname_custom}
          input_urllink_custom={input_urllink_custom}
          input_urlname_personal={input_urlname_personal}
          input_urllink_personal={input_urllink_personal}
          input_company={input_company}
          input_headline={input_headline}
          input_notes={input_notes}
          input_phonenum={input_phonenum}
          input_email={input_email}
          input_address={input_address}
        />
      </div>
      <div className="col-12 col-lg-6 p-3">
        <div className="row m-0">
          <div className="col-12 p-3">
            <div className="card-xl p-4">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <img alt="Personal" src={input_profile_image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = process.env.REACT_APP_NO_PROFILE_IMAGE_URL;
                  }} className="createaccount-personalimage my-2" onClick={() => handleProfileImageUpload()} />
                  <input id="account-upload-profileimage" type="file" className="form-control form-control-sm d-none" onChange={handleProfileImageChange} />
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row">
                    <div className="col-0 col-lg-6 my-3"></div>
                    <div className="col-6 col-lg-3 my-3">
                      <Button onClick={() => window.open(process.env.REACT_APP_PROFILE_URL + "/" + input_shortid, '_blank')} className="w-100" variant="contained" color="primary">View</Button>
                    </div>
                    <div className="col-6 col-lg-3 my-3">
                      <Button onClick={() => setDeleteModalShow(true)} className="w-100" variant="contained" color="error">Delete</Button>
                    </div>
                    <div className="col-12">
                      <TextField label="Short ID" value={input_shortid} className="form-control my-2" disabled={true} required={true} />
                    </div>
                    <div className="col-12">
                      <TextField label="User ID" value={input_id} className="form-control my-2" disabled={true} required={true} />
                    </div>
                    <div className="col-12 mt-2">
                      <FormControl fullWidth>
                        <InputLabel id="business-select-label">Business</InputLabel>
                        <Select labelId="business-select-label" value={input_businessid} label="Business" onChange={handleSelectBusinessIDChange} >
                          {
                            businessdata.map((data, index) => (
                              <MenuItem key={index} value={data.businessid}>{data.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-0 col-lg-9 px-3 pt-3"></div>
          <div className="col-12 col-lg-3 px-3 pt-3 text-end">
            <Button onClick={() => updateUser()} className="px-5" variant="contained" color="success">Update</Button>
          </div>
          <div className="col-12 p-3">
            <div className="card-xl p-4 my-3">
              <TabContext id="a" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Profile" value="1" />
                    <Tab label="Fields" value="2" />
                    <Tab label="Social Media" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <TextField id="firstname" label="First Name" value={input_firstname} onChange={(e) => { setInputFirstName(e.target.value) }} className="form-control my-2" required={true} />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="lastname" label="Last Name" value={input_lastname} onChange={(e) => { setInputLastName(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="suffix" label="Suffix" value={input_suffix} onChange={(e) => { setInputSuffix(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="title" label="Title" value={input_title} onChange={(e) => { setInputTitle(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_personal" label="URL Label (Personal)" value={input_urlname_personal} onChange={(e) => { setInputUrlnamePersonal(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_personal" label="URL (Personal)" value={input_urllink_personal} onChange={(e) => { setInputUrllinkPersonal(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 my-4"></div>
                    <div className="col-12 col-lg-6">
                      <TextField id="department" label="Department" value={input_department} onChange={(e) => { setInputDepartment(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="company" label="Company" value={input_company} onChange={(e) => { setInputCompany(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_company" label="URL Label (Company)" value={input_urlname_company} onChange={(e) => { setInputurlnameCompany(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_company" label="URL (Company)" value={input_urllink_company} onChange={(e) => { setInputUrllinkCompany(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="headline" label="Headline" value={input_headline} onChange={(e) => { setInputHeadline(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="notes" label="Note" value={input_notes} onChange={(e) => { setInputNotes(e.target.value) }} className="form-control my-2" />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <Button onClick={() => addAddressLabel()} className="px-3 ms-3 float-end" variant="contained" startIcon={<LocationOn />} color="primary">Address</Button>
                      <Button onClick={() => addEmailLabel()} className="px-3 ms-3 float-end" variant="contained" startIcon={<Email />} color="primary">Email</Button>
                      <Button onClick={() => addPhonenumLabel()} className="px-3 float-end" variant="contained" startIcon={<LocalPhone />} color="primary">Phone</Button>
                    </div>

                    {
                      input_field.sort((item1, item2) => {
                        const getSortingValue = (category) => {
                          if (category === "phone")
                            return 1;
                          else if (category === "email")
                            return 2;
                          else if (category === "address")
                            return 3;
                        };

                        if (getSortingValue(item1.item) < getSortingValue(item2.item))
                          return -1;
                        else
                          return 0;
                      }).map((data, index) => (
                        <div className="col-12" key={"phone_" + index}>
                          <div className="d-flex">
                            {
                              data.item === "phone" &&
                              <div className="pe-3 account-inputphonelocalfield">
                                <FormControl fullWidth className="my-2">
                                  <InputLabel>Country Code</InputLabel>
                                  <Select value={data.countrycode} label="Country Code" onChange={(e) => handleFieldCountryCodeChange(e, index)}>
                                    {
                                      countrycode.map((data, index) => (
                                        <MenuItem key={index} value={"+(" + data.code + ")"}>{"+(" + data.code + ")"}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              </div>
                            }

                            <TextField label={data.item.charAt(0).toUpperCase() + data.item.slice(1)} value={data.value} onChange={(e) => handleFieldChange(e, index)} className="account-inputvaluefield my-2" />
                            <div className="ps-3 account-inputtypefield">
                              {
                                data.item === "phone" ?
                                  <FormControl fullWidth className="my-2">
                                    <InputLabel>Type</InputLabel>
                                    <Select value={data.type} label="Type" onChange={(e) => handleFieldTypeChange(e, index)}>
                                      <MenuItem value="work">Work</MenuItem>
                                      <MenuItem value="personal">Personal</MenuItem>
                                      <MenuItem value="fax (work)">Fax (Work)</MenuItem>
                                      <MenuItem value="fax (personal)">Fax (Personal)</MenuItem>
                                    </Select>
                                  </FormControl>
                                  :
                                  <FormControl fullWidth className="my-2">
                                    <InputLabel>Type</InputLabel>
                                    <Select value={data.type} label="Type" onChange={(e) => handleFieldTypeChange(e, index)}>
                                      <MenuItem value="work">Work</MenuItem>
                                      <MenuItem value="personal">Personal</MenuItem>
                                    </Select>
                                  </FormControl>
                              }
                              <Button onClick={() => deleteFieldLabel(index)} className="account-inputbuttonfield" variant="contained" color="error"><DeleteIcon></DeleteIcon></Button>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_linkedin" label="URL Label (LinkedIn)" value={input_urlname_linkedin} onChange={(e) => { setInputUrlnameLinkedin(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_linkedin" label="URL (LinkedIn)" value={input_urllink_linkedin} onChange={(e) => { setInputUrllinkLinkedin(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_facebook" label="URL Label (Facebook)" value={input_urlname_facebook} onChange={(e) => { setInputUrlnameFacebook(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_facebook" label="URL (Facebook)" value={input_urllink_facebook} onChange={(e) => { setInputUrllinkFacebook(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_twitter" label="URL Label (Twitter)" value={input_urlname_twitter} onChange={(e) => { setInputUrlnameTwitter(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_twitter" label="URL (Twitter)" value={input_urllink_twitter} onChange={(e) => { setInputUrllinkTwitter(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urlname_custom" label="URL Label (Custom)" value={input_urlname_custom} onChange={(e) => { setInputUrlnameCustom(e.target.value) }} className="form-control my-2" />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField id="urllink_custom" label="URL (Custom)" value={input_urllink_custom} onChange={(e) => { setInputUrllinkCustom(e.target.value) }} className="form-control my-2" />
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default Account;