import React from 'react';

function getPhone(index, href, name, type) {
  return (
    <a key={index} className="profile-template1-info-item" href={'tel:' + href?.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")}>
      <div className="profile-template1-info-logo">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M19.23 15.26L16.69 14.97C16.08 14.9 15.48 15.11 15.05 15.54L13.21 17.38C10.38 15.94 8.06004 13.63 6.62004 10.79L8.47004 8.94001C8.90004 8.51001 9.11004 7.91001 9.04004 7.30001L8.75004 4.78001C8.63004 3.77001 7.78004 3.01001 6.76004 3.01001H5.03004C3.90004 3.01001 2.96004 3.95001 3.03004 5.08001C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26V15.26Z"></path>
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
        <div className="profile-template1-info-type">{type}</div>
      </span>
    </a>
  );
}

function getEmail(index, href, name, type) {
  return (
    <a key={index} className="profile-template1-info-item" href={'mailto:' + href}>
      <div className="profile-template1-info-logo">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L12.53 12.67C12.21 12.87 11.79 12.87 11.47 12.67L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"></path>
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
        <div className="profile-template1-info-type">{type}</div>
      </span>
    </a>
  );
}

function getAddress(index, href, name, type) {
  return (
    <a key={index} className="profile-template1-info-item" href={href} target="_blank" rel="noreferrer">
      <div className="profile-template1-info-logo">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path fillRule="evenodd" clipRule="evenodd" d="M5.8418 9.2625C5.8418 5.80023 8.64203 3 12.1043 3C15.5666 3 18.3668 5.80023 18.3668 9.2625C18.3668 13.9594 12.1043 20.8928 12.1043 20.8928C12.1043 20.8928 5.8418 13.9594 5.8418 9.2625ZM9.8675 9.26252C9.8675 10.4971 10.8695 11.4991 12.1041 11.4991C13.3387 11.4991 14.3407 10.4971 14.3407 9.26252C14.3407 8.02791 13.3387 7.02591 12.1041 7.02591C10.8695 7.02591 9.8675 8.02791 9.8675 9.26252Z"></path>
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
        <div className="profile-template1-info-type">{type}</div>
      </span>
    </a>
  );
}

function getlinkedIn(href, name) {
  return (
    <a className="profile-template1-info-item" href={href}>
      <div className="profile-template1-info-logo">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path d="M21 21H17V14.25C17 13.19 15.81 12.31 14.75 12.31C13.69 12.31 13 13.19 13 14.25V21H9V9H13V11C13.66 9.93 15.36 9.24 16.5 9.24C19 9.24 21 11.28 21 13.75V21ZM7 21H3V9H7V21ZM5 3C5.53043 3 6.03914 3.21071 6.41421 3.58579C6.78929 3.96086 7 4.46957 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7C4.46957 7 3.96086 6.78929 3.58579 6.41421C3.21071 6.03914 3 5.53043 3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3V3Z"></path>
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
      </span>
    </a>
  );
}

function getFacebook(href, name) {
  return (
    <a className="profile-template1-info-item" href={href}>
      <div className="profile-template1-info-logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
      </span>
    </a>
  );
}

function getTwitter(href, name) {
  return (
    <a className="profile-template1-info-item" href={href}>
      <div className="profile-template1-info-logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
      </span>
    </a>
  );
}

function getCompanyURL(href, name) {
  return (
    <a className="profile-template1-info-item" href={href}>
      <div className="profile-template1-info-logo">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <path fillRule="evenodd" clipRule="evenodd" d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4ZM4.00087 18H15.0009V14H4.00087V18ZM15.0009 13H4.00087V9H15.0009V13ZM15.9996 18H19.9996V9H15.9996V18Z"></path>
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
      </span>
    </a>
  );
}

function getURL(href, name) {
  return (
    <a className="profile-template1-info-item" href={href}>
      <div className="profile-template1-info-logo">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z" />
        </svg>
      </div>
      <span>
        <div className="profile-template1-info-name">{name}</div>
      </span>
    </a>
  );
}

const Profile = ({
  input_profile_image,
  input_company_image,
  input_firstname,
  input_lastname,
  input_suffix,
  input_title,
  input_department,
  input_urlname_linkedin,
  input_urllink_linkedin,
  input_urlname_company,
  input_urllink_company,
  input_urlname_facebook,
  input_urllink_facebook,
  input_urlname_twitter,
  input_urllink_twitter,
  input_urlname_custom,
  input_urllink_custom,
  input_urlname_personal,
  input_urllink_personal,
  input_company,
  input_headline,
  input_notes,
  input_email,
  input_phonenum,
  input_address,
  input_field,
}) => {
  return <>
    <div className="profile-template1-frame">
      <div className="profile-template1-card">
        <div>
          <div className="position-relative">
            <div>
              <img className="profile-template1-profileimage" alt={input_firstname + ' ' + input_lastname} src={input_profile_image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = process.env.REACT_APP_NO_PROFILE_IMAGE_URL;
                }} />
            </div>
            <div className="profile-template1-profileimageline">
              <svg preserveAspectRatio="xMinYMax meet" viewBox="0 0 246 57"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M 214.7168,6.1113281 C 195.65271,5.9023124 172.37742,11.948182 137.87305,32.529297 110.16613,49.05604 86.980345,56.862784 65.015625,57 H 65 v 1 H 246 V 11.453125 C 236.0775,8.6129313 226.15525,6.2367376 214.7168,6.1113281 Z"
                  fill="#ffffff" clipRule="evenodd" fillRule="evenodd"></path>
                <path
                  d="M 0,35.773438 V 58 H 65 L 64.97852,57 C 43.192081,57.127508 22.605139,49.707997 0,35.773438 Z "
                  fill="#ffffff" clipRule="evenodd" fillRule="evenodd"></path>
                <path fill="#EA3A2E" clipRule="evenodd" fillRule="evenodd"
                  d="m 0,16.7221 v 19.052 C 45.4067,63.7643 82.6667,65.4583 137.873,32.5286 193.08,-0.401184 219.54,3.87965 246,11.4535 V 6.51403 C 185.24,-16.8661 135.913,29.331 97.6933,40.8564 59.4733,52.3818 33.6467,44.1494 0,16.7221 Z ">
                </path>
              </svg>
              <div className="profile-template1-companylogo-frame">
                <img className="profile-template1-companylogo" alt={input_company} src={input_company_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL;
                  }} />
              </div>
            </div>
          </div>
          <div className="profile-template1-title-frame">
            <div className="profile-template1-title-name">
              {input_firstname} {input_lastname} {input_suffix}
            </div>
            <div className="profile-template1-title-title">
              {input_title}
            </div>
            <div className="profile-template1-title-department">
              {input_department}
            </div>
            <div className="profile-template1-title-company">
              {input_company}
            </div>
          </div>
          <div className="profile-template1-title-headline">
            {input_headline}
          </div>
          <div className="profile-template1-info-frame">
            {
              input_phonenum.map((data, index) => (
                getPhone(index, data.value, data.value, data.type)
              ))
            }

            {
              input_email.map((data, index) => (
                getEmail(index, data.value, data.value, data.type)
              ))
            }

            {
              input_address.map((data, index) => (
                getAddress(index, "https://www.google.com/maps/search/?api=1&query=" + data.value, data.value, data.type)
              ))
            }

            {input_urlname_linkedin !== null && input_urlname_linkedin !== "" &&
              getlinkedIn(input_urllink_linkedin, input_urlname_linkedin)
            }

            {input_urlname_facebook !== null && input_urlname_facebook !== "" &&
              getFacebook(input_urllink_facebook, input_urlname_facebook)
            }

            {input_urlname_twitter !== null && input_urlname_twitter !== "" &&
              getTwitter(input_urllink_twitter, input_urlname_twitter)
            }

            {input_urlname_company !== null && input_urlname_company !== "" &&
              getCompanyURL(input_urllink_company, input_urlname_company)
            }

            {input_urlname_personal !== null && input_urlname_personal !== "" &&
              getURL(input_urllink_personal, input_urlname_personal)
            }

            {input_urlname_custom !== null && input_urlname_custom !== "" &&
              getURL(input_urllink_custom, input_urlname_custom)
            }

          </div>
        </div>
      </div>
    </div>
  </>
};

export default Profile;