import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import AnimatedNumber from "animated-number-react";
import Modal from 'react-bootstrap/Modal';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css"
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import DeleteIcon from '@mui/icons-material/Delete';

const Management = () => {
  const [userdata, setUserData] = useState([]);
  const [profileimage, setProfileImage] = useState(process.env.REACT_APP_NO_PROFILE_IMAGE_URL);
  const [showbutton, setShowButton] = useState(false);
  const [shortid, setShortID] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [department, setDepartment] = useState("");
  const [company, setCompany] = useState("");
  const [usage, setUsage] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [returnDeleteModalShow, setReturnDeleteModalShow] = React.useState(false);

  const theme = createTheme({
    palette: {
      grey: {
        main: '#607d8b',
        light: '#78909c',
        dark: '#78909c',
        contrastText: '#ffffff',
      },
    },
  });

  const deleteUser = () => {
    var json = {
      "shortid": shortid
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "business/deleteUser", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      setDeleteModalShow(false)
      setReturnDeleteModalShow(true)
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "business/getInfoBusiness", {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      let user_data = []
      data.result.forEach((element, index) => {
        user_data.push({ 'ID': element.shortid, 'Name': element.profile_image, 'Business': element.company_image, 'Actions': element.shortid, 'Title': element.title, 'Department': element.department, 'Company': element.company, 'Card Usage': element.count })
      });

      setUserData(user_data)

    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  }, []);

  function DownloadWallet(device) {
    if (device === "apple") {
      let fileName = (company + "_" + name + ".pkpass").replace(" ", "_").toLowerCase()
      fetch(process.env.REACT_APP_API_BASE_URL + "business/vcard_apple/" + shortid, {
        headers: new Headers({
          'Authorization': localStorage.getItem("token")
        })
      }).then((res) => {
        return res.blob()
      }).then((blob) => {
        var _url = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = _url;
        fileLink.download = fileName
        fileLink.click();
      })
    } else {
      fetch(process.env.REACT_APP_API_BASE_URL + "business/vcard_google/" + shortid, {
        headers: new Headers({
          'Authorization': localStorage.getItem("token")
        })
      }).then((response) => response.json()).then((data) => {
        if (data.statuscode === "400") {
          window.location.href = "./Authentication"
        }

        let requestURL = data.result.requestURL;
        window.open(requestURL, "_blank")
      }).catch((err) => {
        window.location.href = "./Authentication"
      });
    }
  }

  const ImageAndTextProfileRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {value && (
        <img
          alt={value.split("#")[1]}
          src={value}
          style={{
            display: 'block',
            width: '50px',
            height: '50px',
            objectFit: 'cover',
            marginRight: '1rem',
            borderRadius: '0.375rem'
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = process.env.REACT_APP_NO_PROFILE_IMAGE_URL;
          }}
        />
      )}
      <p
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          margin: '0'
        }}
      >
        {value.split("#")[1]}
      </p>
    </span>
  );

  const ImageAndTextCompanyRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '70px',
        padding: '10px',
        alignItems: 'center',
      }}
    >
      {value && (
        <img
          alt={value.split("#")[1]}
          src={value}
          style={{
            display: 'block',
            width: '50px',
            marginRight: '1rem',
            borderRadius: '0.375rem'
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL;
          }}
        />
      )}
      <p
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          margin: '0'
        }}
      >
        {
          value.split("#")[1]
        }
      </p>
    </span>
  );

  const ValueRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {value}
    </span>
  );

  const ActionsRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Button href={process.env.REACT_APP_PROFILE_URL + "/" + value} target="_blank" rel="noreferrer" className="me-3" variant="outlined">
        <i className="bi bi-eye"></i>
      </Button>
      <Button href={"/account/" + value} target="_blank" rel="noreferrer" className="me-3" variant="outlined" color="success">
        <i className="bi bi-pencil"></i>
      </Button>
    </span>
  );

  const coldata = [
    {
      field: "ID", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },
    {
      field: "Name", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ImageAndTextProfileRenderer,
    },
    {
      field: "Business", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ImageAndTextCompanyRenderer,
    },
    {
      field: "Title", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },
    {
      field: "Department", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },
    {
      field: "Card Usage", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },
    {
      field: "Actions", width: '200px', filter: false, floatingFilter: false,
      cellRenderer: ActionsRenderer,
    },
  ]

  // GOOD callback, no hook, no stale data
  const onCellClicked = (event) => {
    let profile = event.data['Name']
    setProfileImage(profile.split("#")[0])
    setShortID(event.data['Actions'])
    setName(profile.split("#")[1])
    setTitle(event.data['Title'])
    setDepartment(event.data['Department'])
    setCompany(event.data['Company'])
    setUsage(event.data['Card Usage'])
    setShowButton(true)
  };

  function DeleteModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <div className="mt-2 display-6">Are you sure?</div>
            <div className="mt-4 text-secondary"> Do you really want to delete these records? This process cannot be undone</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Cancel</Button>
            </ThemeProvider>
            <Button onClick={() => deleteUser()} className="px-5 mx-3" variant="contained" color="error">Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DeleteReturnModal(props) {
    return (
      <Modal
        {...props}
        onHide={() => window.location.href = '../management'}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record removed</div>
            <div className="mt-4 text-secondary">The system will redirect to Account page</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={() => window.location.href = '../management'} variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return <>
    <DeleteModal
      show={deleteModalShow}
      onHide={() => setDeleteModalShow(false)}
    />

    <DeleteReturnModal
      show={returnDeleteModalShow}
      onHide={() => setReturnDeleteModalShow(false)}
    />

    <div className="row m-0">
      <div className="col-12 col-lg-4 p-0">
        <div className="p-3 h-100">
          <div className="card-xl p-4">
            <div className="d-inline-flex">
              <img alt="Personal" src={profileimage} className="account-personalimage" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-8 p-0">
        <div className="p-3 h-100">
          <div className="card-xl p-4 h-100">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="profile-template1-title-frame m-0 mb-3">
                  <div className="profile-template1-title-name">{name}</div>
                  <div className="profile-template1-title-title">{title}</div>
                  <div className="profile-template1-title-department">{department}</div>
                  <div className="profile-template1-title-company">{company}</div>
                </div>
                <div className="mt-5 mb-3">
                  <div className="account-label border px-4 py-2 me-4">
                    <h5 className="fw-bold">
                      <AnimatedNumber
                        value={usage}
                        formatValue={value => `${Number(value).toFixed(0)}`}
                      />
                    </h5>
                    <div className="fw-bold text-black-50">Total Frequency</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                {(showbutton &&
                  <>
                    < div >
                      <Button onClick={() => window.open(process.env.REACT_APP_PROFILE_URL + "/" + shortid, '_blank')} className="px-3 mb-3 w-100" color="primary" variant="contained" startIcon={<RemoveRedEyeIcon />}>View</Button>
                    </div>
                    <div>
                      <Button onClick={() => window.open("/account/" + shortid, '_blank')} className="px-3 mb-3 w-100" color="success" variant="contained" startIcon={<EditIcon />}>Edit</Button>
                    </div>
                    <div>
                      <Button onClick={() => setDeleteModalShow(true)} className="px-3 mb-3 w-100" color="error" variant="contained" startIcon={<DeleteIcon />}>Delete</Button>
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <img onClick={() => DownloadWallet('apple')} alt="Add to Google Wallet" className="m-3 w-50" src="/images/US-UK_Add_to_Apple_Wallet_RGB_101421.svg" />
                        {/* <Button onClick={() => DownloadWallet('apple')} className="px-3 mb-3 w-100" color="grey" variant="contained" startIcon={<AppleIcon />}>Wallet</Button> */}
                      </ThemeProvider>
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <img onClick={() => DownloadWallet('google')} alt="Add to Google Wallet" className="m-3" src="/images/enUS_add_to_google_wallet_wallet-button.svg" />
                        {/* <Button onClick={() => DownloadWallet('google')} className="px-3 mb-3 w-100" color="grey" variant="contained" startIcon={<GoogleIcon />}>Wallet</Button> */}
                      </ThemeProvider>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-12 p-3">
      <div className="text-end">
        <Button onClick={() => { window.location.href = "createaccount" }} className="px-5" variant="contained" startIcon={<AddIcon />} > Create</Button>
      </div>
      <div className="ag-theme-alpine mt-3" style={{ height: 1000 }}>
        <AgGridReact
          rowData={userdata}
          columnDefs={coldata}
          pagination={true}
          paginationPageSize={20}
          paginationPageSizeSelector={[20, 50, 100]}
          rowHeight={70}
          onCellClicked={onCellClicked}
        />
      </div>
    </div>
  </>;
};

export default Management;