import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import Resizer from "react-image-file-resizer";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css"
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

const Settings = () => {
  const [businessdata, setBusinessData] = useState([]);
  const [value, setValue] = React.useState('1');
  const [showbutton, setShowButton] = useState(false);
  const [businessid, setBusinessID] = useState("");
  const [input_businessid, setInputBusinessID] = useState("");
  const [input_businessname, setInputBusinessName] = useState("");
  const [input_company_image, setInputCompanyImage] = useState(process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL);
  const [new_company_image, setNewCompanyImage] = useState(null);
  const [returnUpdateModalShow, setReturnUpdateModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [returnDeleteModalShow, setReturnDeleteModalShow] = React.useState(false);

  const theme = createTheme({
    palette: {
      grey: {
        main: '#607d8b',
        light: '#78909c',
        dark: '#78909c',
        contrastText: '#ffffff',
      },
    },
  });


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCompanyImageUpload = (event) => {
    const elem = document.getElementById("setting-upload-companyimage");
    elem.click()
  }

  const handleCompanyImageChange = (event) => {
    setNewCompanyImage(event.target.files[0]);
    setInputCompanyImage(URL.createObjectURL(event.target.files[0]));
  }

  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, 500, 500,
      "PNG", 100, 0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const updateBusiness = async (businessname) => {
    let company_image = input_company_image;

    if (new_company_image != null) {
      company_image = await resizeFile(new_company_image)
    }

    var json = {
      "businessid": input_businessid,
      "name": input_businessname,
      "company_image": company_image
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "settings/updateBusiness", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      setReturnUpdateModalShow(true)
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  };

  const deleteBusiness = () => {
    var json = {
      "businessid": businessid
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "settings/deleteBusiness", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      setDeleteModalShow(false)
      setReturnDeleteModalShow(true)
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "settings/getBusiness", {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      let business_data = []
      data.result.forEach((element, index) => {
        business_data.push({ 'ID': element.businessid, 'Name': element.name, 'Logo': element.company_image, 'Actions': element.businessid + "######" + element.name + "######" + element.company_image })
      });

      setBusinessData(business_data)
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  }, []);

  const ImageARenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '70px',
        width: '70px',
        padding: '10px',
        alignItems: 'center',
      }}
    >
      {value && (
        <img
          alt={value}
          src={value}
          style={{
            display: 'block',
            width: '100%',
            borderRadius: '0.375rem'
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL;
          }}
        />
      )}
    </span>
  );

  const ValueRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      {value}
    </span>
  );

  const ActionsRenderer = ({ value }) => (
    <span
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Button onClick={() => { setDeleteModalShow(true); setBusinessID(value.split("######")[0]); }} variant="outlined" color="error">
        <i className="bi bi-trash3"></i>
      </Button>
    </span >
  );

  const coldata = [
    {
      field: "ID", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },
    {
      field: "Name", flex: 1, filter: true, floatingFilter: true,
      cellRenderer: ValueRenderer,
    },

    {
      field: "Logo", flex: 1, filter: false, floatingFilter: false,
      cellRenderer: ImageARenderer,
    },
    {
      field: "Actions", width: '150px', filter: false, floatingFilter: false,
      cellRenderer: ActionsRenderer,
    },
  ]

  function UpdateReturnModal(props) {
    return (
      <Modal
        {...props}
        onHide={() => window.location.reload()}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record updated</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={() => window.location.reload()} variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DeleteModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <div className="mt-2 display-6">Are you sure?</div>
            <div className="mt-4 text-secondary"> Do you really want to delete these records? This process cannot be undone</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Cancel</Button>
            </ThemeProvider>
            <Button onClick={() => deleteBusiness()} className="px-5 mx-3" variant="contained" color="error">Delete</Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DeleteReturnModal(props) {
    return (
      <Modal
        {...props}
        onHide={() => window.location.href = '../management'}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record removed</div>
          </div>
          <div className="text-center mt-4">
            <button type="button" onClick={() => window.location.reload()} className="btn btn-secondary w-25 mx-3">Noted</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const onCellClicked = (event) => {
    console.log(event.data)
    setInputCompanyImage(event.data['Logo'])
    setInputBusinessID(event.data['ID'])
    setInputBusinessName(event.data['Name'])
    setShowButton(true)
  };

  return <>
    <UpdateReturnModal
      show={returnUpdateModalShow}
      onHide={() => setReturnUpdateModalShow(false)}
    />

    <DeleteModal
      show={deleteModalShow}
      onHide={() => setDeleteModalShow(false)}
    />

    <DeleteReturnModal
      show={returnDeleteModalShow}
      onHide={() => setReturnDeleteModalShow(false)}
    />

    <div className="row m-0">
      <div className="col-12 p-3">
        <div className="card-xl p-4">
          <TabContext id="a" value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label="Business" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="row">

                {(showbutton &&
                  <>
                    <div className="col-12">
                      <div className="text-end">
                        <Button onClick={() => { updateBusiness(); }} className="px-5" variant="contained" color="success" startIcon={<EditIcon />} > Update</Button>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex my-3">
                      <div className="setting-companylogo-frame" onClick={() => handleCompanyImageUpload()}>
                        <img alt="Company" src={input_company_image} onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL;
                        }} className="setting-companyimage" />
                      </div>
                      <input id="setting-upload-companyimage" type="file" className="form-control form-control-sm d-none" onChange={handleCompanyImageChange} />
                    </div>
                    <div className="col-12 col-lg-6">
                      <TextField disabled label="Business ID" value={input_businessid} onChange={(e) => { setInputBusinessID(e.target.value) }} className="form-control mt-3" required={true} />
                      <TextField label="Business Name" value={input_businessname} onChange={(e) => { setInputBusinessName(e.target.value) }} className="form-control mt-3 mb-3" required={true} />
                    </div>
                  </>
                )}
                <div className="col-12">
                  <div className="ag-theme-alpine" style={{ height: 700 }}>
                    <AgGridReact
                      rowData={businessdata}
                      columnDefs={coldata}
                      pagination={false}
                      rowHeight={70}
                      onCellClicked={onCellClicked}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  </>;
};

export default Settings;