import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AgGridReact } from 'ag-grid-react';
import Resizer from "react-image-file-resizer";
import TextField from '@mui/material/TextField';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css"
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const ImportBussiness = () => {
  const [userdata, setUserData] = useState([]);
  const [businessdata, setBusinessData] = useState([]);
  const [input_businessid, setInputBusinessID] = useState("");
  const [createBusinessShow, setCreateBusinessShow] = useState(true);
  const [input_businessname, setInputBusinessName] = useState("");
  const [input_company_image, setInputCompanyImage] = useState(process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL);
  const [new_company_image, setNewCompanyImage] = useState(null);
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [returnCreateModalShow, setReturnCreateModalShow] = React.useState(false);
  const [returnDuplicateCreateModalShow, setReturnDuplicateCreateModalShow] = React.useState(false);

  const theme = createTheme({
    palette: {
      grey: {
        main: '#607d8b',
        light: '#78909c',
        dark: '#78909c',
        contrastText: '#ffffff',
      },
    },
  });

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const coldata = [
    { field: "First Name" },
    { field: "Last Name" },
    { field: "Suffix" },
    { field: "Title" },
    { field: "Department" },
    { field: "Company" },
    { field: "Headline" },
    { field: "Email1 (Work)" },
    { field: "Email2 (Work)" },
    { field: "Email1 (Personal)" },
    { field: "Email2 (Personal)" },
    { field: "Phone1 (Work)" },
    { field: "Phone2 (Work)" },
    { field: "Phone1 (Personal)" },
    { field: "Phone2 (Personal)" },
    { field: "Fax (Work)" },
    { field: "Fax (Personal)" },
    { field: "Address1 (Work)" },
    { field: "Address2 (Work)" },
    { field: "Address1 (Personal)" },
    { field: "Address2 (Personal)" },
    { field: "URL (Personal)" },
    { field: "URL (Customer)" },
    { field: "URL (Company)" },
    { field: "URL (Linkedin)" },
    { field: "URL (Facebook)" },
    { field: "URL (Twitter)" },
    { field: "Notes" }
  ]

  const handleSelectBusinessIDChange = (event) => {
    if (event.target.value === "-1") {
      setInputBusinessID("");
      setCreateBusinessShow(true)
    } else {
      setInputBusinessID(event.target.value);
      setCreateBusinessShow(false)
    }
  };

  const handleCompanyImageUpload = (event) => {
    const elem = document.getElementById("setting-upload-companyimage");
    elem.click()
  }

  const handleCompanyImageChange = (event) => {
    setNewCompanyImage(event.target.files[0]);
    setInputCompanyImage(URL.createObjectURL(event.target.files[0]));
  }

  const resizeFile = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, 500, 500,
      "PNG", 100, 0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  const handleDownloadChange = (event) => {
    window.open("template.csv", "_blank");
  };

  const handleCSVDataChange = (event) => {
    var reader = new FileReader();
    reader.addEventListener('load', function (e) {
      let csvdata = e.target.result;
      let parsedata = [];
      let newLinebrk = csvdata.split("\n");
      for (let i = 0; i < newLinebrk.length; i++) {
        parsedata.push(newLinebrk[i].split(","))
      }
      console.log(parsedata)

      let business_data = []
      parsedata.forEach((element, index) => {
        if (index !== 0)
          if (element[23] !== undefined)
            business_data.push(
              {
                'First Name': element[0], 'Last Name': element[1], 'Suffix': element[2], 'Title': element[3], 'Department': element[4],
                'Company': element[5], 'Headline': element[6], 'Email1 (Work)': element[7], 'Email2 (Work)': element[8], 'Email1 (Personal)': element[9], 'Email2 (Personal)': element[10],
                'Phone1 (Work)': element[11], 'Phone2 (Work)': element[12], 'Phone1 (Personal)': element[13], 'Phone2 (Personal)': element[15], 'Fax (Work)': element[15], 'Fax (Personal)': element[16],
                'Address1 (Work)': element[17], 'Address2 (Work)': element[18], 'Address1 (Personal)': element[19], 'Address2 (Personal)': element[20], 'URL (Personal)': element[21],
                'URL (Customer)': element[22], 'URL (Company)': element[23], 'URL (Linkedin)': element[24],
                'URL (Facebook)': element[25], 'URL (Twitter)': element[24], 'Notes': element[26]
              }
            )
      });

      setUserData(business_data)
    });

    reader.readAsBinaryString(event.target.files[0]);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "settings/getBusiness", {
      headers: new Headers({
        'Authorization': localStorage.getItem("token")
      })
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      let business_data = []
      data.result.forEach((element, index) => {
        business_data.push({ 'businessid': element.businessid, 'name': element.name })
      });

      setBusinessData(business_data)
    }).catch((err) => {
      console.log(err.message);
    });
  }, []);

  const createBusiness = async () => {
    let company_image = input_company_image;

    if (new_company_image !== null) {
      company_image = await resizeFile(new_company_image)
    }

    var json = {
      "businessid": input_businessid,
      "name": input_businessname,
      "company_image": company_image
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "settings/createBusiness", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token")
      },
      body: JSON.stringify(json)
    }).then((response) => response.json()).then((data) => {
      if (data.statuscode === "400") {
        window.location.href = "./Authentication"
      }

      if (data.status === "SUCCESS") {
        createGroupUser()
      } else {
        setCreateModalShow(false)
        setReturnDuplicateCreateModalShow(true)
      }
    }).catch((err) => {
      window.location.href = "./Authentication"
    });
  };

  const createGroupUser = async () => {
    userdata.forEach(async (element, index) => {
      let fieldlist = ['Email1 (Work)', 'Email2 (Work)', 'Email1 (Personal)', 'Email2 (Personal)', 'Phone1 (Work)', 'Phone2 (Work)', 'Phone1 (Personal)', 'Phone2 (Personal)', 'Fax (Work)', 'Fax (Personal)', 'Address1 (Work)', 'Address2 (Work)', 'Address1 (Personal)', 'Address2 (Personal)']
      let input_field = []
      fieldlist.forEach((field_element, index) => {
        let field_countrycode = ""
        let field_num = ""
        if (element[field_element].split(" ").length > 1) {
          field_countrycode = element[field_element].split(" ")[0]
          field_num = element[field_element].split(" ")[1]
        } else {
          field_countrycode = "+(852)";
          field_num = element[field_element];
        }

        if (index === 0 || index === 1)
          input_field.push({ item: "email", type: "work", value: element[field_element] })
        else if (index === 2 || index === 3)
          input_field.push({ item: "email", type: "personal", value: element[field_element] })
        if (index === 4 || index === 5)
          input_field.push({ item: "phone", type: "work", value: field_num, countrycode: field_countrycode })
        else if (index === 6 || index === 7)
          input_field.push({ item: "phone", type: "personal", value: field_num, countrycode: field_countrycode })
        else if (index === 8)
          input_field.push({ item: "phone", type: "fax (work)", value: field_num, countrycode: field_countrycode })
        else if (index === 9)
          input_field.push({ item: "phone", type: "fax (personal)", value: field_num, countrycode: field_countrycode })
        else if (index === 10 || index === 11)
          input_field.push({ item: "address", type: "work", value: element[field_element] })
        else if (index === 12 || index === 13)
          input_field.push({ item: "address", type: "personal", value: element[field_element] })
      });

      var json = {
        "businessid": input_businessid,
        "id": element['First Name'].trim().replace(/\s/g, '.').toLowerCase(),
        "profile_image": process.env.REACT_APP_NO_PROFILE_IMAGE_URL,
        "firstname": element['First Name'],
        "lastname": element['Last Name'],
        "suffix": element['Suffix'],
        "title": element['Title'],
        "department": element['Department'],
        "urlname_linkedin": element['URL (Linkedin)'],
        "urllink_linkedin": element['URL (Linkedin)'],
        "urlname_company": element['URL (Company)'],
        "urllink_company": element['URL (Company)'],
        "urlname_facebook": element['URL (Facebook)'],
        "urllink_facebook": element['URL (Facebook)'],
        "urlname_twitter": element['URL (Twitter)'],
        "urllink_twitter": element['URL (Twitter)'],
        "urlname_custom": element['URL (Customer)'],
        "urllink_custom": element['URL (Customer)'],
        "urlname_personal": element['URL (Personal)'],
        "urllink_personal": element['URL (Personal)'],
        "company": element['Company'],
        "headline": element['Headline'],
        "notes": element['Notes'],
        "field": JSON.stringify(input_field)
      }

      await fetch(process.env.REACT_APP_API_BASE_URL + "business/createUser", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem("token")
        },
        body: JSON.stringify(json)
      }).then((response) => response.json()).then((data) => {
        if (data.statuscode === "400") {
          window.location.href = "./Authentication"
        }
      }).catch((err) => {
        window.location.href = "./Authentication"
      });
    });

    setCreateModalShow(false)
    setReturnCreateModalShow(true)
  }

  function CreateModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-success display-3"></i>
            <div className="mt-2 display-6">Are you sure?</div>
            <div className="mt-4 text-secondary"> Do you really want to create these records?</div>
          </div>
          <div className="text-center mt-4">
            <Button onClick={() => createBusiness()} className="px-5 mx-3" variant="contained" color="primary">Create</Button>
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Cancel</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function CreateReturnModal(props) {
    return (
      <Modal
        {...props}
        onHide={() => window.location.href = '../management'}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-check-circle text-success display-3"></i>
            <div className="mt-2 display-6">Record Created</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={() => window.location.href = '../management'} variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function DuplicateCreateReturnModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <div className="mt-2 display-6">Created Failed</div>
            <div className="mt-4 text-secondary">The ID duplicated</div>
          </div>
          <div className="text-center mt-4">
            <ThemeProvider theme={theme}>
              <Button onClick={props.onHide} className="px-5 mx-3" variant="contained" color="grey">Noted</Button>
            </ThemeProvider>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return <>
    <CreateModal
      show={createModalShow}
      onHide={() => setCreateModalShow(false)}
    />

    <CreateReturnModal
      show={returnCreateModalShow}
      onHide={() => setReturnCreateModalShow(false)}
    />

    <DuplicateCreateReturnModal
      show={returnDuplicateCreateModalShow}
      onHide={() => setReturnDuplicateCreateModalShow(false)}
    />

    <div className="row m-0">
      <div className="col-12 p-3">
        <div className="card-xl p-4">
          <div className="row">
            <div className="col-12">
              <div className="text-end">
                <Button onClick={() => { handleDownloadChange(); }} className="px-5" variant="contained" startIcon={<CloudDownloadIcon />} color="primary">Template</Button>
                <Button component="label" role={undefined} className="px-5 mx-3" variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />} color="success" onChange={handleCSVDataChange}>
                  Import Data
                  <VisuallyHiddenInput type="file" />
                </Button>

                {((input_businessid !== "" && input_businessname !== "" && createBusinessShow === true) || createBusinessShow === false ?
                  <Button onClick={() => { setCreateModalShow(true); }} className="px-5" variant="contained" startIcon={<AddIcon />}>Create</Button>
                  :
                  <Button disabled onClick={() => { setCreateModalShow(true); }} className="px-5" variant="contained" startIcon={<AddIcon />}>Create</Button>
                )}
              </div>
            </div>
            <div className="col-0 col-lg-6"></div>
            <div className="col-12 col-lg-6 mt-3">
              <FormControl fullWidth>
                <InputLabel id="business-select-label">Business</InputLabel>
                <Select labelId="business-select-label" defaultValue="-1" label="Business" onChange={handleSelectBusinessIDChange}>
                  <MenuItem value="-1">Create New Business</MenuItem>
                  {
                    businessdata.map((data, index) => (
                      <MenuItem key={index} value={data.businessid}>{data.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>

            {(createBusinessShow === true &&
              <>
                <div className="col-12 col-lg-6 d-flex my-3">
                  <div className="setting-companylogo-frame" onClick={() => handleCompanyImageUpload()}>
                    <img alt="Company" src={input_company_image} onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = process.env.REACT_APP_DEFAULT_COMPANY_IMAGE_URL;
                    }} className="setting-companyimage" />
                  </div>
                  <input id="setting-upload-companyimage" type="file" className="form-control form-control-sm d-none" onChange={handleCompanyImageChange} />
                </div>
                <div className="col-12 col-lg-6">
                  <TextField label="Business ID" value={input_businessid} onChange={(e) => { setInputBusinessID(e.target.value) }} className="form-control mt-3" required={true} />
                  <TextField label="Business Name" value={input_businessname} onChange={(e) => { setInputBusinessName(e.target.value) }} className="form-control mt-3 mb-3" required={true} />
                </div>
              </>
            )}

            <div className="col-12">
              <div className="ag-theme-alpine mt-3" style={{ height: 700 }}>
                <AgGridReact
                  rowData={userdata}
                  columnDefs={coldata}
                  pagination={true}
                  paginationPageSize={20}
                  paginationPageSizeSelector={[20, 50, 100]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};

export default ImportBussiness;