import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Authentication from "./pages/Authentication";
import Statistics from "./pages/Statistics.jsx";
import Account from "./pages/Account";
import CreateAccount from "./pages/CreateAccount";
import Management from "./pages/Management.jsx";
import Settings from "./pages/Settings";
import ImportBussiness from "./pages/ImportBussiness.jsx";
import Menu from "./pages/components/Menu.jsx";
import BaseMenu from "./pages/components/BaseMenu.jsx";

function Layout() {
  const location = useLocation();
  const isAuthenticationRoute = location.pathname === "/Authentication" || location.pathname === "/";

  return (
    <div className="d-flex">
      <div className="content-frame">
        {isAuthenticationRoute ? <BaseMenu /> : <Menu />}
        <div className="section">
          <Routes>
            <Route index element={<Authentication />} />
            <Route path="authentication" element={<Authentication />} />
            <Route path="statistics" element={<Statistics />} />
            <Route path="management" element={<Management />} />
            <Route path="createaccount" element={<CreateAccount />} />
            <Route path="account/:shortid" element={<Account />} />
            <Route path="importbussiness" element={<ImportBussiness />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);