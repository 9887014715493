const BaseMenu = () => {
    return <>
        <nav className="navbar navbar-expand-lg menu-frame px-5 py-3">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img alt="VOXA Logo" className="menu-logo" src="/images/fulllogo.png" />
                </a>
            </div>
        </nav>
    </>
}

export default BaseMenu;