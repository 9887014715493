const Menu = () => {
    return <>
        <nav className="navbar navbar-expand-lg menu-frame px-5 py-3">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img alt="VOXA Logo" className="menu-logo" src="/images/fulllogo.png" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item py-3">
                            <a className="nav-a menu-label" href="/statistics">Statistics</a>
                        </li>
                        <li className="nav-item py-3">
                            <a className="nav-a menu-label" href="/management">Accounts</a>
                        </li>
                        <li className="nav-item py-3">
                            <a className="nav-a menu-label" href="/importbussiness">Import Data</a>
                        </li>
                        <li className="nav-item py-3">
                            <a className="nav-a menu-label" href="/settings">Settings</a>
                        </li>
                        <li className="nav-item py-3">
                            <a className="nav-a menu-label" href="/authentication">Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>
}

export default Menu;